import styled from "@emotion/styled"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { Box, Flex } from "@rebass/grid/emotion"

// entire footer:
export const FooterContainer = styled.footer`
  background: ${theme.wild.color.nova.base.black};
  color: ${theme.wild.color.nova.base.white};

  &[data-mousedown] {
    *:focus {
      outline: none !important;
    }
  }

  h2 {
    color: ${theme.wild.color.nova.base.white};
  }

  a {
    & * {
      color: ${theme.wild.color.nova.base.white} !important;
    }
  }

  input:focus-visible {
    outline: none !important;
  }

  .section-header {
    color: ${theme.wild.color.nova.transparency.white[60]};
    letter-spacing: 0.5px;
    margin-bottom: 16px;
    width: 100%;

    @media (min-width: 1024px) {
      margin-bottom: 24px;
    }
  }
`

export const MainBox = styled(Box)`
  width: 100%;
  max-width: 1600px;
  margin: auto;
  padding: 48px 24px !important;

  @media (min-width: 768px) {
    padding: 48px 32px 48px 32px !important;
  }

  @media (min-width: 1024px) {
    padding: 80px !important;
  }
`

export const FooterWrap = styled.div`
  flex-wrap: wrap;
  padding: 40px 0;
  display: flex;
  max-width: 100%;

  @media (min-width: 768px) {
    padding: 0 !important;
  }
`

export const LogoWrap = styled.div`
  margin-bottom: 32px;
  svg {
    width: 48px;
    height: 48px;

    @media (min-width: 768px) {
      width: 40px;
      height: 40px;
    }

    path {
      fill: white;
    }
  }
`

export const FooterWrapNoPad = styled.div`
  padding: 0 25px;
  display: flex;
`

export const StyledLeftSide = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 44%;
    margin-right: 12%;
  }

  @media (min-width: 1024px) {
    width: 31.5%;
    margin-right: 7.5%;
  }

  section {
    width: 100%;
    padding: 0;

    &.keep {
      margin-bottom: 32px;
      @media (min-width: 768px) {
        margin-bottom: 40px;
      }
    }

    &.patent {
      margin-bottom: 32px;

      @media (min-width: 768px) {
        margin-bottom: 19px;
      }
      @media (min-width: 1024px) {
        margin-bottom: 0;
      }
    }

    h2 {
      color: ${theme.wild.color.master.base.white};
      margin-bottom: 16px;

      @media (min-width: 768px) {
        margin-bottom: 8px;
      }
    }

    p {
      color: ${theme.wild.color.nova.transparency.white[60]};
    }
  }

  .nowrap {
    white-space: nowrap;
  }
`

export const StyledRightSide = styled.div`
  padding: 74px 0 0 0;
  width: 50%;

  @media (min-width: 768px) {
    width: 44%;
  }

  @media (min-width: 1024px) {
    padding: 0;
    width: 61%;
  }
`

export const StyledLinksSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 135px 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 48px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    grid-column-gap: 48px;
  }

  @media (min-width: 1180px) {
    grid-column-gap: 64px;
  }
  @media (min-width: 1440px) {
    grid-column-gap: 96px;
  }
`

export const StyledCopyrightWrapper = styled(Flex)`
  width: 100%;
  margin-top: 80px;
  text-align: left;

  @media (min-width: 768px) {
    margin-top: 16px;
    align-items: center;
  }

  @media (min-width: 1024px) {
    margin-top: 40px;
    flex-wrap: wrap;
  }
`

export const StyledCopyright = styled(Box)`
  position: relative;
  color: ${theme.wild.color.nova.transparency.white[60]};
  margin-bottom: 32px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    width: 50%;
    margin-right: 5.7%;
  }

  @media (min-width: 1024px) {
    margin-bottom: 0;
    width: 31%;
    margin-right: 8%;
    margin-top: auto;
  }

  p {
    color: ${theme.wild.color.nova.transparency.white[60]};
    width: 100%;
  }
`

export const StyledSocial = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${theme.wild.color.nova.transparency.white[10]};
  border-radius: 4px;
  width: 256px;
  height: 64px;
  padding: 0 12px;

  @media (min-width: 768px) {
    height: 56px;
    margin-top: auto;
    margin-bottom: auto;
  }

  @media (min-width: 1024px) {
    width: 240px;
    padding: 0 20px;
    margin-bottom: 0;
  }

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 100%;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: ${theme.wild.color.nova.base.white};
      }
    }
  }
`

export const StyledLinks = styled.div`
  width: 50%;
  position: relative;

  &.col-wellness {
    width: 50%;

    @media (min-width: 768px) {
      width: 100%;
    }

    @media (min-width: 1024px) {
      width: 20%;
    }

    @media (min-width: 1440px) {
      width: 14%;
    }
  }

  &.col-company {
    width: 50%;
    padding-left: 16px;

    @media (min-width: 768px) {
      width: 100%;
      padding-left: 0;
    }

    @media (min-width: 1024px) {
      width: 20%;
    }

    @media (min-width: 1440px) {
      width: 14%;
    }
  }

  &.col-legal-x {
    width: 100%;

    @media (min-width: 480px) {
      display: none;
    }
  }

  &.col-legal-s {
    width: 33%;
    display: none;

    @media (min-width: 480px) {
      display: block;
    }
  }

  &.col-legal-l {
    width: 100%;

    .link-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 24px;

      @media (min-width: 1024px) {
        display: flex;
        flex-direction: column;
      }
    }

    .link {
      &[class$="personal information"] {
        min-width: 135px;
      }
    }

    @media (min-width: 768px) {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-self: flex-start;
    }
  }

  > h4 {
    line-height: 19px;
    margin: 0 0 24px 0;
    text-transform: capitalize;
    letter-spacing: 0.5px;
  }

  .link {
    min-width: 111px;
    line-height: 14px;
    margin-bottom: 16px;

    @media (min-width: 1024px) {
      margin-bottom: 24px;
      line-height: 1.3;
    }

    p {
      display: inline-block;
    }
  }

  .link:last-of-type {
    margin: 0;
  }
`

export const FormEmailAddress = styled.form`
  width: 100%;
  margin-bottom: 32px;

  &.email-m {
    display: block;

    @media (min-width: 768px) {
      display: none;
    }
  }

  &.email-l {
    display: none;
    margin-bottom: 0px;

    @media (min-width: 768px) {
      display: block;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  &.email-xl {
    display: none;

    @media (min-width: 1024px) {
      margin-bottom: 0;
      display: block;
    }
  }

  div.form-group {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 48px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid ${theme.wild.color.nova.transparency.white[20]};
    overflow: hidden;

    @media (min-width: 768px) {
      height: 56px;
    }
  }

  input.form-email {
    border-radius: 0 !important;
    box-sizing: border-box;
    width: calc(100% - 52px);
    height: 100%;
    padding: 16px 0 16px 16px;
    line-height: 16px;
    color: ${theme.wild.color.nova.transparency.white[60]};
    background: ${theme.wild.color.nova.transparency.white[10]};

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 1.3;
      color: ${theme.wild.color.nova.transparency.white[60]};

      @media (min-width: 768px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  button.form-submit {
    box-sizing: border-box;
    width: 52px;
    padding: 0;
    height: 99%;
    background: ${theme.wild.color.nova.transparency.white[10]};
    display: flex;
    justify-content: center;
    align-items: center;

    &.mth {
      height: 46px;

      @media (min-width: 768px) {
        height: 56px;
      }
    }

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: ${theme.wild.color.nova.transparency.white[60]};
      }
    }
  }

  &:not(.mth) {
    p {
      color: ${theme.wild.color.nova.base.white};
      padding-bottom: 16px;

      @media (min-width: 768px) {
        padding-bottom: 8px;
      }

      @media (min-width: 1024px) {
        padding-bottom: 16px;
      }
    }
  }
`

// email validation error element
export const EmailError = styled.div`
  padding-top: 5px;

  > p:not(.mth) {
    color: ${theme.wild.color.nova.functional.error} !important;
    padding-bottom: 0;
  }
`

export const DesktopWrapper = styled.div`
  display: none;
  max-width: 100% !important;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
  }
`

export const MobileWrapper = styled.div`
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`

export const StyledMobileLinks = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 32px;
`

export const LoginBox = styled(Box)`
  &.email-m {
    display: block;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      display: none;
    }
  }

  &.email-l {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  &.email-xl {
    display: none;
    margin-top: 40px;

    a {
      font-size: 14px !important;
      line-height: 20px !important;
    }

    @media (min-width: 768px) {
      margin-top: 24px;
    }

    @media (min-width: 1024px) {
      display: block;
      margin-top: 40px;
    }
  }

  p {
    color: ${theme.wild.color.nova.transparency.white[60]};
    margin-bottom: 16px;

    @media (min-width: 768px) {
      margin-bottom: 8px;
    }

    @media (min-width: 1024px) {
      margin-bottom: 4px;
    }
  }

  a.Textlink {
    display: inline-flex;
    border-bottom: 1px solid ${theme.wild.color.nova.base.white};

    .label {
      font-size: 14px;
    }
  }
`

export const TabletEmailForm = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    display: none;
  }
`

export const SignUpTerms = styled.p`
  font-size: 0;
  padding-bottom: 0 !important;
  margin-top: 16px;

  @media (min-width: 768px) {
    margin-top: 8px;
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    margin-top: 16px;
  }

  p,
  span,
  a {
    font-size: 12px;
    line-height: 1.4;
    color: ${theme.wild.color.nova.transparency.white[60]};

    @media (min-width: 1024px) {
      font-size: 12px;
    }
  }
`

export const StyledSignupLarge = styled(Box)`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    flex: 1;
  }
`
export const SpecialWrap = styled.div``

export const RightSideWrap = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    width: 61%;
    grid-column-gap: 96px;
  }
  @media (min-width: 1180px) {
    grid-column-gap: 128px;
  }
  @media (min-width: 1440px) {
    grid-column-gap: 88px;
  }
`
export const SocialOuterWrap = styled.div`
  align-self: center;
  @media (min-width: 1024px) {
    width: 40%;
    align-self: flex-end;
    /* width: 240px; */
  }
`
